<script setup>
import HeightInput from '@/components/HeightInput.vue';
import { useActorStore } from '@/stores/actors';
import { checkForm, findChildren, setFieldValidity } from '@/utilities/vue.js';
import { openDialog, showError } from '@/dialogs';
import { computed, getCurrentInstance, inject, nextTick, ref, watch } from 'vue';
import ActorResume from '@/components/ActorResume.vue';
import { useUppy } from '@/composables/uploads.js';
import { RESUME_FORMATS, RESUME_MAX_SIZE } from '@/utilities/constants.js';
import { ReportError } from '@/error_handling.js';
import trimStart from 'lodash/trimStart';
import EditActorSkillRanking from '@/components/EditActorSkillRanking.vue';
import { statusEnumValues } from '@/utilities/labels';
import { WizardActorEditingKey } from '@/composables/wizard_actor_editing';
import ActorPasswordDialog from '@/dialogs/ActorPasswordDialog.vue';

const { actor, setExtraDirty, originalActor, registerValidityCheck, registerSaveHook } = inject(WizardActorEditingKey);

const websiteRef = ref(null);

const actorsStore = useActorStore();

const newResumeFile = ref(null);
const removeResume = ref(false);
const phone = ref('');
const oldAgentInfo = ref('');

const hasAgent = computed({
  get() {
    return typeof actor.value.agentInformation === 'string';
  },
  set(value) {
    if (value) {
      if (typeof actor.value.agentInformation !== 'string') {
        actor.value.agentInformation = oldAgentInfo.value;
      }
    } else {
      if (typeof actor.value.agentInformation === 'string') {
        oldAgentInfo.value = actor.value.agentInformation;
      }
      actor.value.agentInformation = null;
    }
  },
});

const skillRankingLabel = computed(() => {
  if ((actor.value.skillRanking || []).length > 0) {
    return 'Please put these skills in order';
  } else {
    return 'Please choose your best skill';
  }
});

const uppy = useUppy({
  idName: 'resume_uploader',
  allowedTypes: RESUME_FORMATS,
  maxFileSize: RESUME_MAX_SIZE,
  startUploader(file) {
    return actorsStore.startResumeUpload({
      actorId: actor.value.id,
      contentType: file.type,
      fileName: file.name,
    });
  },
  finishedUpload() {
    newResumeFile.value = null;
  },
});

watch(
  originalActor,
  () => {
    phone.value = originalActor.value.phone || '';
  },
  { immediate: true },
);

watch(
  [newResumeFile, removeResume],
  () => {
    setExtraDirty(newResumeFile.value !== null || removeResume.value);
  },
);

// Methods
const vm = getCurrentInstance();

async function saveUpload() {
  if (removeResume.value) {
    await actorsStore.deleteResumeUpload({ actorId: actor.value.id });
    removeResume.value = false;
  } else if (uppy.value.getFiles().length) {
    const uploadStatus = await uppy.value.upload();
    if (uploadStatus.failed.length > 0) return false;
  }
  return true;
}

const presentingOptionsRef = ref(null);
const unionAffiliationRef = ref(null);
const skillRankingFieldRef = ref(null);

function requireSkillRanking() {
  const invalid = [];
  if ((actor.value.skillRanking || []).length === 0) {
    invalid.push(skillRankingFieldRef.value);
    setFieldValidity(skillRankingFieldRef.value, 'danger', 'Please choose your best skill');
  } else {
    setFieldValidity(skillRankingFieldRef.value);
  }
  return invalid;
}

function requireCheckbox(parent) {
  const invalid = [];

  const checkboxes = findChildren(parent, /^(OCheckbox)$/);
  if (!checkboxes.some(r => r.modelValue)) {
    invalid.push(...checkboxes);
    setFieldValidity(parent, 'danger', 'Please select at least one option');
  } else {
    setFieldValidity(parent);
  }
  return invalid;
}

function requireRadio(parent) {
  const invalid = [];

  const checkboxes = findChildren(parent, /^(ORadio)$/);
  if (!checkboxes.some(r => r.modelValue)) {
    invalid.push(...checkboxes);
    setFieldValidity(parent, 'danger', 'Please select at least one option');
  } else {
    setFieldValidity(parent);
  }
  return invalid;
}

function checkValidity() {
  const invalid = checkForm(vm);
  invalid.push(...requireCheckbox(presentingOptionsRef.value));
  invalid.push(...requireRadio(unionAffiliationRef.value));
  invalid.push(...requireSkillRanking());
  return invalid;
}

registerValidityCheck(checkValidity);
registerSaveHook(async (_updatedActor) => {
  if (!await saveUpload()) {
    return 'Could not upload resume';
  } else {
    return true;
  }
});

function validatedPhone(data) {
  if (data.valid) {
    actor.value.phone = data.number || '';
  }
}

function handleUpload(file) {
  if (!file) return;

  removeResume.value = false;

  uppy.value.clear();

  try {
    uppy.value.addFile(file);
  } catch (ex) {
    showError({ message: ex.message });
    ReportError(ex);
  }
}

function deleteResume() {
  removeResume.value = true;
  newResumeFile.value = null;
  uppy.value.clear();
}

function prefixUrl() {
  const value = actor.value.website;
  let newValue = null;
  if (value) {
    const pos = value.indexOf(':');
    if (pos < 1) {
      newValue = `http://${trimStart(value, '/:')}`;
    }
  }
  if (newValue) {
    actor.value.website = newValue;
    nextTick(() => {
      websiteRef.value.checkHtml5Validity();
    });
  }
}

function setPassword() {
  openDialog(ActorPasswordDialog, {
    actor: actor.value,
  });
}
</script>

<template lang="pug">
OField(label="E-Mail")
  OInput( readonly :model-value="actor.email" type="email" )
OField(label="Set Password (Optional)")
  OButton(
    v-tooltip="!actor.id ? 'Save first before setting a password.' : ''"
    :disabled="!actor.id"
    variant="info"
    outlined
    @click="setPassword"
  ) Set password
OField(label="Full name")
  OInput( v-model="actor.name" type="text" required)
OField(label="Preferred pronouns")
  OInput(v-model="actor.pronouns" type="text")
OField(label="Phone")
  vue-tel-input(
    v-model="phone"
    input-options.placeholder="Enter your phone number"
    @validate="a => validatedPhone(a)"
  )
OField(label="Height")
  HeightInput(v-model="actor.height")
OField(label="Website")
  OInput(
    ref="websiteRef"
    v-model="actor.website"
    placeholder="http://mywebsite.com/"
    type="url"
    @blur="prefixUrl"
  )
OField(ref="skillRankingFieldRef" :label="skillRankingLabel")
  EditActorSkillRanking(v-model="actor.skillRanking")
OField(ref="unionAffiliationRef" label="Union Affiliation")
  ORadio(
    v-for="s in statusEnumValues('ActorAffiliation')"
    :key="s.id"
    v-model="actor.unionAffiliation"
    :native-value="s.id"
    name="unionAffiliation"
    @update:model-value="$nextTick(() => requireRadio(unionAffiliationRef))"
  ) {{ s.label }}
OField(label="Agent Information")
  ORadio( v-model="hasAgent" :native-value="false" name="hasAgent" ) No
  ORadio( v-model="hasAgent" :native-value="true" name="hasAgent" ) Yes
OField(v-if="hasAgent")
  OInput(v-model="actor.agentInformation" required placeholder="Enter agent details and contact information" type="textarea" )
OField(ref="presentingOptionsRef" label="Presenting role")
  OCheckbox(v-model="actor.malePresentingRole" @update:model-value="$nextTick(() => requireCheckbox(presentingOptionsRef))") Male presenting role
  OCheckbox(v-model="actor.femalePresentingRole" @update:model-value="$nextTick(() => requireCheckbox(presentingOptionsRef))") Female presenting role
  OCheckbox(v-model="actor.nonBinaryRole" @update:model-value="$nextTick(() => requireCheckbox(presentingOptionsRef))") Non-binary role
OField(label="Drivers license")
  OCheckbox(v-model="actor.driversLicense") Valid drivers license
OField(label="Local/Non-Local  (live within 50 miles of the theater)")
  ORadio( v-model="actor.isLocal" :native-value="true" name="isLocal" ) Local
  ORadio( v-model="actor.isLocal" :native-value="false" name="isLocal" ) Non-Local
OField(label="Additional Information")
  OInput( v-model="actor.additionalInformation" placeholder="Enter any additional information" type="textarea" )
OField(label="Resume")
  div.mr-2
    .notification.is-warning.is-light(v-if="removeResume")
      | Press save to remove the resume
    .notification.is-warning.is-light(v-else-if="newResumeFile")
      | Press save to upload new resume
    ActorResume(
      v-else
      :actor-id="actor.id"
      empty-message="No resume"
      deletable
      @delete="deleteResume"
    )
  OUpload(
    v-model="newResumeFile"
    drag-drop
    :accept="RESUME_FORMATS.join(',')"
    @update:model-value="handleUpload"
  )
    p.upload-help
      OIcon(icon="upload")
      br
      | Drop here or click to upload
      |
      i a PDF up to 5MB in size
        br
        i (images, .docx, and .odt are also allowed)
</template>

<style scoped>

</style>
